ggApp.Admin = {
    init: function (e) {
        $('select[name="Status"]').on("change",
            function() {
                $('.k-grid').data('kendoGrid').dataSource.read();
                $('.k-grid').data('kendoGrid').refresh();
            });
    },
    getStatus: function() {
        return { "status": $('select[name="Status"]').val() };
    }
};