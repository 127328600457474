ggApp.Gallery = {
    deatilsInit: function (){
        $('.slider-for').slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            asNavFor: '.slider-nav',
            centerMode: true,
            centerPadding: 90
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            SlidesToScroll: 1,
            centerMode: true,
            dots: false,
            arrows: true,
            focusOnSelect: true,
            asNavFor: '.slider-for',
            responsive: [
				{
				    breakpoint: 1259,
				    settings: {
				        slidesToShow: 3
				    }
				},
				{
				    breakpoint: 1000,
				    settings: {
				        slidesToShow: 3
				    }
				},
				{
				    breakpoint: 405,
				    settings: {
				        slidesToShow: 1
				    }
				}
            ]
        });
    }
};
