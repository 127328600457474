ggApp.Images = {
    init: function () {
        var images;
        //Get the existing images
        $.ajax({
            url: '/Admin/GetExistingImages',
            type: 'POST',
            data: JSON.stringify({
                'id': $("#inpThumbnail").data("gallery-id")
            }),
            async: false,
            contentType: 'application/json; charset=utf-8',
            success: function (existingFiles) {
                images = JSON.parse(existingFiles);
            },
            error: function (result) {
            }
        });

        //Filter out the thumbnail for the Thumbnail specific uploader
        var thumbnail = images.filter(function (element) { return element.ImageTypeId == $("#inpThumbnail").data("image-type-id") })

        //Thumbnail uploader
        $("#inpThumbnail").kendoUpload({
            multiple: false,
            async: {
                saveUrl: '/Admin/SaveImage/' + $("#inpThumbnail").data("gallery-id") + "?ImageTypeId=" + $("#inpThumbnail").data("image-type-id"),
                removeUrl: '/Admin/RemoveImage/' + $("#inpThumbnail").data("gallery-id") + "?ImageTypeId=" + $("#inpThumbnail").data("image-type-id"),
                autoUpload: true
            },
            files: thumbnail,
            upload: ggApp.Images.onUpload,
            localization: {
                dropFilesHere: "Or drop files here to upload."
            }
        });

        //Filter out the thumbnail for the Thumbnail specific uploader
        var carousal = images.filter(function (element) { return element.ImageTypeId == $("#inpCarousal").data("image-type-id") });

        //Large Carosol images
        $("#inpCarousal").kendoUpload({
            multiple: true,
            async: {
                saveUrl: '/Admin/SaveImage/' + $("#inpCarousal").data("gallery-id") + "?ImageTypeId=" + $("#inpCarousal").data("image-type-id"),
                removeUrl: '/Admin/RemoveImage/' + $("#inpCarousal").data("gallery-id") + "?ImageTypeId=" + $("#inpCarousal").data("image-type-id"),
                autoUpload: true
            },
            files: carousal,
            upload: ggApp.Images.onUpload,
            localization: {
                dropFilesHere: "Or drop files here to upload."
            }
        });

    },
    onUpload: function (e) {
        // Array with information about the uploaded files
        var files = e.files;

        // Check the extension of each file and abort the upload if it is not one of the extensions below
        $.each(files, function () {
            if (this.extension.toLowerCase() != ".png"
                && this.extension.toLowerCase() != ".jpg"
                && this.extension.toLowerCase() != ".jpeg"
                && this.extension.toLowerCase() != ".tiff"
                && this.extension.toLowerCase() != ".tif"
                && this.extension.toLowerCase() != ".bmp") {
                alert("This file type cannot be uploaded.")
                e.preventDefault();
            }

            //if exceeds upload size restriction of 2MB
            if (this.size > 2*1024*1024) {
                alert("One or more files are larger than 2MB and are not allowed to be uploaded.");
                e.preventDefault(); 
            }
        });
    }
};
