ggApp.Search = {
    init: function () {
        $('button[data-component-type="search"]').on('click', function () {
            ggApp.Search.Search(this);
        });
        $('a[data-role="clear-selection"]').on('click', function () {
            $('input:checkbox').removeAttr('checked');
            $('input[type="text"]').val("");
        });
        $('form').on("keypress",
            function(event) {
                ggApp.Search.ReturnSearch(event);
            });
    },
    Search: function (button) {
        $.ajax({
            type: 'POST',
            dataType: 'html',
            url: $(button).data("component-url"), 
            data: $(button).closest('form').serialize(),
        }).success(function (partialView) {
            $("#divPartial").html(partialView);
        }).error(function (results) {
            console.log('Error/n' + results);
        });
    },
    ReturnSearch: function (e) {
        if (e.keyCode === 13) {
            $('button[data-component-type="search"]').trigger('click');
            e.preventDefault();
        }
    }
};
