ggApp.SubmissionForm = {
    init: function () {
        $('button[data-component-role="add-contact"]').on('click', function () { ggApp.SubmissionForm.AddContact(); });
    },
    AddContact: function () {
        var lastElement = $('div[data-component-type="edit-contact"]').last();
        var index = $(lastElement).data("component-index") + 1;
        var prefix = $(lastElement).data("component-prefix");
        $.ajax({
            url: '/Admin/AddContact',
            type: 'POST',
            data: {
                "indexTemp": index,
                "prefix": prefix
            },
            async: false,
            //contentType: 'application/json; charset=utf-8',
            success: function (partial) {
                $(lastElement).after(partial);
            }, 
            error: function (jqXHR, textStatus, errorThrown) {
            }
        }); 
    }
};
