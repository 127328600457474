ggApp.Map = {
    init: function () {
        if ($('script[src^="//maps.googleapis.com"]').length === 0){
            var s = document.createElement('script');
            s.setAttribute('src','//maps.googleapis.com/maps/api/js?sensor=false&callback=initMapsCallback&key=AIzaSyCeWXQyiU2kQjrUCEprOKxvF68RyyQABjY');
            document.body.appendChild(s);
        }
        if(!window.initMapsCallback){
            window.initMapsCallback = function(){
                if(!window.mapsInitObj && Object.keys(window.mapsInitObj).length > 0){
                    //console.log('No maps present');
                    return true;
                }
                else {
                    var options = {};
                    //console.log('Lets do it! ' + Object.keys(window.mapsInitObj).length + ' maps detected.');
                    for(var map in mapsInitObj){
                        options = {}; //reset to a blank object
                        options['center'] = new google.maps.LatLng(mapsInitObj[map].centerLat, mapsInitObj[map].centerLong);
                        options['zoom'] = mapsInitObj[map].zoom;
                        //options['mapTypeControl'] = mapsInitObj[map].mapTypeControl;
                        //options['navigationControl'] = mapsInitObj[map].navigationControl;
                        gmap = new google.maps.Map(document.getElementById(mapsInitObj[map].canvasId), options);
                        /*google.maps.event.addListener(gmap, 'click', function(){
                            console.log(infowindow);
                            infowindow.close();
                        });*/
                        if(Object.keys(mapsInitObj[map].locations).length > 0){
                            var marker;
                            var locationCounter = 0;
                            for(glocation in mapsInitObj[map].locations){
                                locationCounter++;
                                var latlng = new google.maps.LatLng(mapsInitObj[map].locations[glocation].latitude, mapsInitObj[map].locations[glocation].longitude),
                                myIcon = "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=";

                                //if(mapsInitObj[map].locations[glocation].pinType == "custom"){
                                if("standard" == "custom"){
                                    if("true" == "true"){
                                        myIcon += locationCounter;
                                    }
                                    else {
                                        myIcon += "%E2%80%A2";
                                    }

                                    myIcon += "|" + mapsInitObj[map].locations[glocation].pinColor + "|" + mapsInitObj[map].locations[glocation].pinTextColor;

                                    var infowindow = new google.maps.InfoWindow(),
                                            marker = new google.maps.Marker({
                                                map: gmap,
                                                icon: myIcon,
                                                title: mapsInitObj[map].locations[glocation].name,
                                                position: latlng,
                                                zIndex: Math.round(latlng.lat() * -100000) << 5,
                                            }),
                                        content = '<strong>' + mapsInitObj[map].locations[glocation].name + '<strong><br />' + $('#mapPinDesc' + mapsInitObj[map].locations[glocation].tmpId).html();

                                    if(mapsInitObj[map].locations[glocation].showReadMore == "Yes"){
                                        switch(mapsInitObj[map].locations[glocation].readMoreType){
                                            case "External URL":
                                                content += '<br/><a href="' + mapsInitObj[map].locations[glocation].readMoreExternalLink + '">' + mapsInitObj[map].locations[glocation].readMore + '</a>';
                                                break;
                                            case "CMS Page":
                                                content += '<br/><a href="' + mapsInitObj[map].locations[glocation].readMoreCMSLink + '">' + mapsInitObj[map].locations[glocation].readMore + '</a>';
                                                break;
                                        }
                                    }

                                    google.maps.event.addListener(marker,'click', (function(marker, content, infowindow, theMap){
                                        return function() {
                                            infowindow.setContent(content);
                                            infowindow.open(theMap, marker);
                                        };
                                    })(marker,content,infowindow, gmap));
                                }
                                else {
                                    var infowindow = new google.maps.InfoWindow(),
                                        marker = new google.maps.Marker({
                                            map: gmap,
                                            title: mapsInitObj[map].locations[glocation].name,
                                            position: latlng,
                                            zIndex: Math.round(latlng.lat() * -100000) << 5,
                                        }),
                                        content = '<strong>' + mapsInitObj[map].locations[glocation].name + '<strong><br />' + $('#mapPinDesc' + mapsInitObj[map].locations[glocation].tmpId).html();
                                    if(mapsInitObj[map].locations[glocation].showReadMore == "Yes"){
                                        switch(mapsInitObj[map].locations[glocation].readMoreType){
                                            case "External URL":
                                                content += '<br/><a href="' + mapsInitObj[map].locations[glocation].readMoreExternalLink + '">' + mapsInitObj[map].locations[glocation].readMore + '</a>';
                                                break;
                                            case "CMS Page":
                                                content += '<br/><a href="' + mapsInitObj[map].locations[glocation].readMoreCMSLink + '">' + mapsInitObj[map].locations[glocation].readMore + '</a>';
                                                break;
                                        }
                                    }
                                    google.maps.event.addListener(marker,'click', (function(marker, content, infowindow, theMap){
                                        return function() {
                                            infowindow.setContent(content);
                                            infowindow.open(theMap, marker);
                                        };
                                    })(marker,content,infowindow, gmap));
                                }
                            }
                        }
                    }
                }
            }
        }
        if(!window.mapsInitObj){
            window.mapsInitObj = {};
        }
        mapsInitObj["map"] = {
            canvasId: 'map_canvas',
            zoom: 15, 
            centerLat: 43.074282, 
            centerLong: -89.409133,
            mapTypeControl: true,
            navigationControl: true
        }
        var theLocations = [];

        //Loop here and push all lat/lon + names in database to the map
        $.ajax({
            url: '/Map/GetPins',
            type: 'POST',
            data: {},
            async: false,
            contentType: 'application/json; charset=utf-8',
            success: function (galleries) {
                for (var i = 0; i < galleries.length; i++)
                {
                    galleries[i];
                    theLocations.push({
                        name: galleries[i].name,
                        latitude: galleries[i].latitude,
                        longitude: galleries[i].longitude,
                        pinType: "standard",
                        pinNum: true,
                        pinColor: "fe7569",
                        pinTextColor: "000000",
                        tmpId: i,
                        showReadMore: "Yes",
                        readMore: "Read More",
                        readMoreType: "CMS Page",
                        readMoreCMSLink: "/Gallery/Details/" + galleries[i].id,
                        readMoreExternalLink: ""
                    });
                    $('#mapDesc_510').append("<span id='mapPinDesc" + i + "'><p>" + galleries[i].address +"</p></span>");
             
                }

                mapsInitObj["map"]["locations"] = theLocations;

            },
            error: function (jqXHR, textStatus, errorThrown) {
            }
        });
    }
};