$(document).foundation();

ggApp = {
    currentComponent: {},
    handleAjaxError: function (xmlHttpRequest, textStatus) {
        if (xmlHttpRequest.status === 401 || xmlHttpRequest.status === 302) {
            window.location.replace(window.applicationPath + '/Account/login');
        } else if (textStatus !== 'abort' && xmlHttpRequest.readyState !== 0) {
            if (xmlHttpRequest.responseText !== '') {
                $('[data-role="descriptiveErrorMessage"]', '#descriptiveErrorModal').html(xmlHttpRequest.responseText);
                $('#descriptiveErrorModal').foundation('open');
            } else {
                $('#loadErrorModal').foundation('open'); 
            }
        }
    },
    initToasts: function () {
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": true,
            "positionClass": 'toast-top-right',
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": '300',
            "hideDuration": '1000',
            "timeOut": '5000',
            "extendedTimeOut": '1000',
            "showEasing": 'swing',
            "hideEasing": 'linear',
            "showMethod": 'fadeIn',
            "hideMethod": 'fadeOut'
        }
    },
    page: function () {
        this.id = 0;
        this.loads = 0;
        this.components = {};
        this.selectors = {};
        this.startLoad = function () {
            this.loads++;
            if (this.loads === 1) this.selectors.loading.show();
        }
        this.endLoad = function () {
            this.loads--;
            if (this.loads === 0) this.selectors.loading.hide();
        }
        this.init = function () { alert('not implemented.'); };
    },
    init: function () {
        this.initToasts();
        this.initCheckboxLists();
        this.initCmsEdits();
        $(document).foundation();
        window.onbeforeunload = function (e) {
            if ($('[data-component-role="EditCard"]').length > 0 && $('[data-component-role="EditCard"]').html().length > 0) {
                var dialogText = 'You have unsaved changes, are you sure you wish to discard them?';
                e.returnValue = dialogText;
                return dialogText;
            }
        };
        $('button.back-button').on('click', function () { window.history.back(); });
    },
    initCmsEdits: function () {
        $(document).on('click', 'a[data-role="page-content-edit"]', function () {
            //Ajax in the edit or save
            button = $(this);
            $.ajax({
                type: 'GET',
                dataType: 'html',
                url: $(button).data("edit-url")
            }).success(function (partialView) {
                if (!$(button).closest('.pagecontent-placeholder').next().is('form')) {
                    $(button).closest('.pagecontent-placeholder').after(partialView);
                    $(button).closest('.pagecontent-placeholder').hide();
                    $(button).closest('.pagecontent-placeholder').next('form').find("[data-role='richtext']").jqte();
                }
            }).error(function (results) {
                console.log('Error/n' + results);
            });
        });

        $(document).on('click', '[data-role="cancel-page-content-edit"]', function () {
            var clickableEditArea = $(this).closest('form').prev(".pagecontent-placeholder");
            $(clickableEditArea).show();
            $(clickableEditArea).next('form').remove();
        });

        $(document).on('click', '[data-role="save-page-content-edit"]', function () {
            button = $(this);
            $.ajax({
                type: 'POST',
                dataType: 'html',
                data: $(button).closest('form').serialize(),
                url: $(button).data("save-url")
            }).success(function (partialView) {
                $(button).closest('form').parent().html(partialView);
            }).error(function (results) {
                console.log('Error/n' + results);
            });
        });
    },
    initCheckboxLists: function () {
        $('[type="checkbox"][data-role="selectall"]', this.el).each(function () {
            $('[type="checkbox"][name="' + $(this).attr('name') + '"]').change(function () {
                if ($(this).attr('data-role') === 'selectall') {
                    $('[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', this.checked);
                } else {
                    var selAll = $('[type="checkbox"][data-role="selectall"][name="' + $(this).attr('name') + '"]');
                    //check for all selected, or not all selected
                    if (selAll.is(':checked')) {
                        if (!this.checked) {
                            selAll.prop('checked', this.checked);
                        }
                    } else {
                        if ($('[type="checkbox"][name="' + $(this).attr('name') + '"]:not(:checked)').length === 1) {
                            selAll.prop('checked', this.checked);
                        }
                    }
                }
            });
        });
    },
    gridSuccessHandler:  function (e) {
        if (e.type !== undefined) {
            switch (e.type.toLowerCase()) {
                case "update":
                    toastr.success("Updated successfully!");
                    $('div[data-role="grid"]').data('kendoGrid').dataSource.read();
                    $('div[data-role="grid"]').data('kendoGrid').refresh();
                    break;
                case "create":
                    if (e.response.Data[0].CreatedBy !== null) {
                        toastr.success("Created successfully!");
                    }
                    $('div[data-role="grid"]').data('kendoGrid').dataSource.read();
                    $('div[data-role="grid"]').data('kendoGrid').refresh();
                    break;
            }
        }
    },
    gridErrorHandler: function (e) {
        if (e.errors) {
            var message = "Errors:\n";
            $.each(e.errors, function (key, value) {
                if ('errors' in value) {
                    $.each(value.errors, function () {
                        message += this + "\n";
                    });
                }
            });
            toastr.error(message);
        }
    }
};

$(function () {
    ggApp.init();
});